



































































import { Getter, Action } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
// @ts-ignore
import { debounce } from 'lodash';
function Debounce(delay: number) {
    return (target: any, prop: string) => {
        return {
            configurable: true,
            enumerable: false,
            value: debounce(target[prop], delay),
        };
    };
}

@Component({
    components: {
        PagePresetWithSidebar: () => import('@/layouts/presets/WithSidebar.vue'),
        ProductRow: () => import('@/components/product/ProductRow.vue'),
        ProductCategoryRow: () => import('@/components/product/category/ProductCategoryRow.vue'),
        OrderHistory: () => import('@/components/order/OrderHistory.vue'),
        ContactBanner: () => import('@/components/banners/ContactBanner.vue'),
        ContactToggle: () => import('@/components/contact/ContactToggle.vue'),
        PromoCarousel: () => import('@/components/promo/PromoCarousel.vue'),
        ProductSearchDropdown: () => import('@/components/product/ProductSearchDropdown.vue'),
    },
})
export default class PageOverview extends Vue {
    @Getter('user/user') user!: Auth;
    @Getter('company/viewing') company!: Company;
    @Getter('product/overview') overview!: OverviewState;
    @Getter('pageLoading') pageLoading!: boolean;
    @Action('account/read') readAccount!: (payload: { id: number; no_set?: boolean }) => Promise<Auth>;
    @Action('product/read') readProduct!: (payload: { slug: string }) => void;
    @Action('product/orderList') orderList!: (payload: {
        company_slug: string;
        no_set?: boolean;
        collection_slug?: string;
        per_page: string;
        after?: Array<string | number>;
        q?: string;
        ids?: number[];
    }) => Promise<Product[]>;

    localProducts: Product[] = [];
    showDropdown: boolean = false;
    searchText: string = '';
    contactPerson: Auth | null = null;

    i18n: string = 'views.overview';

    @Debounce(500)
    searchInput(val: string) {
        if (this.searchText !== '') {
            this.indexProducts().then((response: Product[]) => {
                this.showDropdown = true;
                this.localProducts = response;
            });
        } else {
            this.showDropdown = false;
            this.localProducts = [];
        }
    }

    searchInputRedirect() {
        if (this.searchText !== '') {
            this.$router.push({ name: 'products', query: { q: this.searchText } });
        }
    }

    @Watch('company', { immediate: true })
    companyChanged(company: Company) {
        if (company && company.account_id) {
            this.readAccount({ id: company.account_id, no_set: true }).then((a) => {
                this.contactPerson = a;
            });
        }
    }

    private indexProducts(): Promise<Product[]> {
        return this.orderList({ company_slug: this.user.company_collection_slug, no_set: true, q: this.searchText, per_page: '4' });
    }

    private productSelected(product: Product) {
        this.showDropdown = false;
        this.searchText = '';

        if (product && product.slug) {
            this.readProduct({ slug: product.slug });
        }
    }
}
